// extracted by mini-css-extract-plugin
export var alternateSection = "componentContent-module--alternate-section--885b5";
export var button = "componentContent-module--button--c9e38";
export var cafeFormIntro = "componentContent-module--cafe-form-intro--8842c";
export var cafe_simpleTextTitle = "componentContent-module--cafe_simpleTextTitle--06275";
export var cafe_title = "componentContent-module--cafe_title--cd848";
export var center = "componentContent-module--center--d9bab";
export var content = "componentContent-module--content--a42e5";
export var cta = "componentContent-module--cta--1db20";
export var dropdownWrapper = "componentContent-module--dropdown-wrapper--0a076";
export var highlight = "componentContent-module--highlight--3d188";
export var left = "componentContent-module--left--6f398";
export var newRecipePopupContent = "componentContent-module--new-recipe-popup-content--34cf9";
export var newRecipePopupSubTitle = "componentContent-module--new-recipe-popup-sub-title--ada5a";
export var newRecipePopupTitle = "componentContent-module--new-recipe-popup-title--ae07d";
export var newRecipePopupTitleWrapper = "componentContent-module--new-recipe-popup-title-wrapper--e9fb6";
export var newRecipePopupWrapper = "componentContent-module--new-recipe-popup-wrapper--9d1b8";
export var redButton = "componentContent-module--red-button--7724e";
export var redTransparentButton = "componentContent-module--red-transparent-button--4c644";
export var regenWrap = "componentContent-module--regen-wrap--9dce2";
export var right = "componentContent-module--right--d104f";
export var simpleContent = "componentContent-module--simple-content--d4394";
export var simpleTextDescription = "componentContent-module--simple-text-description--9ffc0";
export var simpleTextTitle = "componentContent-module--simple-text-title--36fe2";
export var subTitle = "componentContent-module--sub-title--3a89a";
export var title = "componentContent-module--title--8d294";
export var titleWrapper = "componentContent-module--title-wrapper--36697";
export var transparentButton = "componentContent-module--transparent-button--1261a";
export var wrapper = "componentContent-module--wrapper--6820b";
export var wrapperSimpleText = "componentContent-module--wrapper-simple-text--e027d";