import React from "react"
import * as styles from './recipeHero.module.scss'
import Title from "../Shared/Title/Title"
import { GatsbyImage } from "gatsby-plugin-image"
import SocialMedia from '../SocialMediaIcons/SocialMedia'
const RecipeHero = ({ data, recipePopupChecker }) => {
  const { heroImage, mainTitle, subTitle, title} = data
  if(!heroImage && !title){
    return <div> </div>
  }


  return (
    <div className="container container-lg-np">
      <div className={recipePopupChecker ? styles.newRecipePopupWrapper : styles.wrapper}>
        <div className={recipePopupChecker ? styles.newRecipePopupHeroImage : styles.heroImage}>
          <GatsbyImage
            image={heroImage.gatsbyImageData}
            alt={`${heroImage.title} image`}
            loading="eager"
            objectFit="cover"
          />
        </div>
        <div className={recipePopupChecker ? styles.newRecipePopupContent : styles.content}>
          <div className={recipePopupChecker ? styles.newRecipePopupTitle : styles.title}>
            <Title title = {title} headingLevel = 'h1'/>
          </div>
          <p className={styles.subTitle}>{subTitle}</p>
          <div className={styles.socialMedia}>
            <SocialMedia />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecipeHero
