// extracted by mini-css-extract-plugin
export var button = "recipeHero-module--button--10cfc";
export var cafeFormIntro = "recipeHero-module--cafe-form-intro--4fa37";
export var content = "recipeHero-module--content--1b0bd";
export var heroImage = "recipeHero-module--hero-image--076bd";
export var highlight = "recipeHero-module--highlight--39f55";
export var newRecipePopupContent = "recipeHero-module--new-recipe-popup-content--9de47";
export var newRecipePopupHeroImage = "recipeHero-module--new-recipe-popup-hero-image--df3b7";
export var newRecipePopupTitle = "recipeHero-module--new-recipe-popup-title--9972a";
export var newRecipePopupWrapper = "recipeHero-module--new-recipe-popup-wrapper--16112";
export var redButton = "recipeHero-module--red-button--fbe48";
export var redTransparentButton = "recipeHero-module--red-transparent-button--8f4f7";
export var socialMedia = "recipeHero-module--social-media--25ade";
export var subTitle = "recipeHero-module--subTitle--35908";
export var title = "recipeHero-module--title--b07d2";
export var transparentButton = "recipeHero-module--transparent-button--e4b2b";
export var wrapper = "recipeHero-module--wrapper--04431";