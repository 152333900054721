import React, { useState, useEffect } from "react"
import { RichText } from "../Shared/RichText/RichText"
import * as styles from "./componentContent.module.scss"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import Title from "../Shared/Title/Title"
import { Link } from "gatsby"
import { useLocalPath } from "../hooks/useLocalPath"

const Dropdown = ({ data, setShow, show }) => {
  const { title, backgroundImage, content, contentType, subTitle, backgroundColor } = data
  const showContent = () => {
    if (contentType === "Dropdown") {
      setShow(!show)
    }
  }
  return (
    <div className={`container ${styles.dropdownWrapper}`}style={{
        background: backgroundImage
          ? `url(${backgroundImage.file?.url}) no-repeat center center / cover`
          : backgroundColor
          ? backgroundColor
          : "",
      }}>
      <div className="container-lg ">
        <p className={styles.subTitle}>{subTitle}</p>
        <div className={styles.titleWrapper} onClick={showContent}>
            <h2 className={styles.title}>{title} </h2>
            { show ? <FiChevronUp /> : <FiChevronDown />}
        </div>
        <div className={styles.content}>
            {show && (
            <div>
                <div className={styles.content}>
                <RichText content={content} />
                </div>
            </div>
            )}
        </div>
      </div>
    </div>
  )
}

const UnderlineContent = ({ data, recipePopupChecker }) => {
  const { title, backgroundImage, content, contentColor, subTitle} = data

  return (
    <div className={`container container-lg ${recipePopupChecker ? styles.newRecipePopupWrapper : styles.wrapper}`}>
      <div className={subTitle ? styles.newRecipePopupTitleWrapper : styles.titleWrapper}>
        <h2 className={recipePopupChecker ? styles.newRecipePopupTitle : styles.title}>{title} </h2>
        {subTitle ? <p className={styles.newRecipePopupSubTitle}>({subTitle})</p> : null}
      </div>
      <div
        className={recipePopupChecker ? styles.newRecipePopupContent : styles.content}
        style={{
          background: backgroundImage
            ? `url(${backgroundImage.file?.url}) no-repeat center center / cover`
            : "",
        }}
      >
        <div className={styles.simpleContent} style={{color:contentColor}}>
          <div className={recipePopupChecker ? styles.newRecipePopupContent : styles.content}>
            <RichText content={content} />
          </div>
        </div>
      </div>
    </div>
  )
}

const SimpleText = ({ data, regen }) => {
  const {
    title,
    titleBackgroundColor,
    titleFontSize,
    titleColor,
    headingLevel,
    leftVine,
    rightVine,
    content,
    backgroundColor,
    backgroundImage,
    cta,
    sectionWidth,
    contentColor,
    contentAlignment,
    additionalContent,
  } = data



  const Width = sectionWidth => {
    switch (sectionWidth) {
      case "sm":
        return "container-sm"
      case "md":
        return "container-md"
      default:
        return "container-lg"
    }
  }
  const alignmentText = alignment => {
    switch (alignment) {
      case "Left":
        return styles.left
      case "Right":
        return styles.right
      default:
        return styles.center
    }
  }


  return (
    <div
      className={`container ${regen ? styles.regenWrap : ""}`}
      style={{
        background: backgroundImage
          ? `url(${backgroundImage.file?.url}) no-repeat center center / cover`
          : backgroundColor
          ? backgroundColor
          : "",
      }}
    >
      <div className={Width(sectionWidth)}>
        <div className={`${styles.wrapperSimpleText}`}>
          <div
            className={titleFontSize ? styles.cafe_simpleTextTitle : styles.simpleTextTitle}
            style={{
              color: titleColor ? titleColor : "",
              background: titleBackgroundColor ? titleBackgroundColor : "",
            }}
          >
            <Title title={title} headingLevel={headingLevel} />
          </div>
          {content && (
            <div
              style={{ color: contentColor ? contentColor : "" }}
              className={`${styles.simpleTextDescription} ${alignmentText(
                contentAlignment
              )}`}
            >
              <RichText content={content} />
            </div>
          )}
          {cta && (
            <div className={styles.cta}>
              <Link
                to={
                  cta.externalLink
                    ? cta.externalLink
                    : cta.slug
                    ? useLocalPath(cta.slug)
                    : useLocalPath(cta?.target?.slug)
                }
                className="red-button"
                target={cta.externalLink ? "_blank" : "_self"}
              >
                {cta.title}{" "}
              </Link>
            </div>
          )}
          {additionalContent && (
            <div>
              <RichText content={additionalContent} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const AlternateText = ({data}) => {
  const {title,firstItalicContent,titleFontSize, content, headingLevel, cta} = data
  let  el 
  const scollHandler = (e) =>{
    e.preventDefault()
    window.scrollTo({top: el, behavior: "smooth"})
  }

  useEffect (() =>{
    if(window !== undefined){
      el =  Number(document.getElementById('image-slider')?.offsetTop) - 126
      if(document.getElementById('cafe-btn')){
      document.getElementById('cafe-btn').addEventListener("click", scollHandler)
    }
    }

    return () =>{
      if(window !== undefined){
        el =  Number(document.getElementById('image-slider')?.offsetTop) - 126
        if(document.getElementById('cafe-btn')){
          document.getElementById('cafe-btn').removeEventListener("click", scollHandler)
        }
      }
    }
  })
  return(
    <div className={` container container-lg ${styles.wrapperSimpleText}`}>
      <div className={styles.alternateSection}>
        <div  className={titleFontSize ? styles.cafe_title : styles.title}>
          <Title title = {title} headingLevel={headingLevel} firstItalicContent={firstItalicContent}/>
        </div>
        <div className={styles.content}>
          <RichText content={content} />
          {cta && (
            <div className={styles.cta} id = {!cta.externalLink && !cta.slug ? 'cafe-btn' : 'btn'}>
              <Link
                to={
                  cta.externalLink
                    ? cta.externalLink
                    : cta.slug
                    ? useLocalPath(cta.slug)
                    : useLocalPath(cta?.target?.slug)
                }
                className="red-button"
                target={cta.externalLink ? "_blank" : "_self"}
              >
                {cta.title}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const ComponentContent = ({ data, regen , recipePopupChecker}) => {
  const [show, setShow] = useState(true)
  const { title, contentType } = data
  switch (contentType) {
    case "Dropdown":
      return <Dropdown data={data} setShow={setShow} show={show} />
    case "Underline-Content":
      return <UnderlineContent data={data} recipePopupChecker={recipePopupChecker} />
    case "SimpleText":
      return <SimpleText data={data} regen={regen ? true : false} />
    case "Alternate-Text":
      return <AlternateText data={data}/>
    default:
      title && <h2 className={styles.title}>{title} </h2>
  }

  return <div></div>
}

export default ComponentContent
