import React from 'react'
import { graphql } from 'gatsby'
import ComponentContent from '../components/ComponentContent/ComponentContent'
import RecipeHero from '../components/RecipeHero/RecipeHero'
import SectionGrid from '../components/SectionGrid/SectionGrid'
import SectionText from '../components/SectionText/SectionText'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import Breadcrum from '../components/Breadcrum/Breadcrum'

const Recipe = ({
  pageContext,
  data: {
    contentfulPageRecipe:{
      slug,
      seoTitle,
      seoDescription,
      sections,
      breadcrum,
      thumbnailTitle
    },
    allContentfulPageRecipe: {
        nodes
    }
  }
}) => {
  const lang = useUrl()
  if(!sections){
    return <div></div>
  }
  return (
    <section id='recipe'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}/>
      {breadcrum && <Breadcrum data = {breadcrum} title={thumbnailTitle}/>}
      {
        slug && sections.map((section, index) =>{
          switch(section.__typename){
            case "ContentfulComponentHero":
              return <RecipeHero data = {section} key = {index}/>
            case "ContentfulSectionWithTextAndImage":
              return <SectionText data = {section} key = {index}/>
            case "ContentfulComponentContent":
              return <ComponentContent data = {section} key = {index}/>
            case "ContentfulComponentGridSection":
              return  <SectionGrid data = {section} key = {index}/>
          }
        })
      }
    </section>
  )
}

export const RecipeQuery = graphql`
    query RecipeQuery( $id: String!, $contentful_id: String! ){
        contentfulPageRecipe(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            breadcrum {
              title
              slug
            }
            thumbnailTitle
            sections {
                __typename
                ...componentHero
                ...sectionWithTextImage
                ...componentContent
                ...contentfulComponentGridSection
            }
        }
        allContentfulPageRecipe(
            filter: {
                contentful_id: { eq: $contentful_id },
                id: { ne: $id },
                slug: { ne: null }
            }
        ) {
            nodes {
                slug
                node_locale
            }
        }
    }
  `
export default Recipe