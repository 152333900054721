// extracted by mini-css-extract-plugin
export var bottomText = "sectionText-module--bottom-text--482ad";
export var bottomVine = "sectionText-module--bottom-vine--778c0";
export var bottomVineImg = "sectionText-module--bottom-vine-img--7b4f9";
export var button = "sectionText-module--button--80749";
export var cafeFormIntro = "sectionText-module--cafe-form-intro--c65d6";
export var description = "sectionText-module--description--01893";
export var highlight = "sectionText-module--highlight--78a62";
export var image = "sectionText-module--image--20809";
export var redButton = "sectionText-module--red-button--a4995";
export var redTransparentButton = "sectionText-module--red-transparent-button--54abb";
export var title = "sectionText-module--title--151de";
export var titleWrapper = "sectionText-module--title-wrapper--a0916";
export var transparentButton = "sectionText-module--transparent-button--ab2aa";
export var wrapper = "sectionText-module--wrapper--8438f";