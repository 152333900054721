import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { RichText } from '../Shared/RichText/RichText'
import Title from '../Shared/Title/Title'
import * as styles from './sectionText.module.scss'
const SectionText = ({ data }) => {
    const { title, description, backgroundColor, image, bottomVine, bottomText } = data
    return (
        <div className='container' style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}>
            <div className='container-lg'>
                <div className={styles.wrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}><Title title={title} /></div>
                        {image && <div className={styles.image}> 
                            <GatsbyImage
                                image={image.gatsbyImageData}
                                alt={`${image.title} image`}
                            />
                        </div>}
                    </div>
                    <div className={styles.description}>
                        <RichText content={description} />
                    </div>
                    <div className={styles.bottomText}>
                        <p>{bottomText}</p>
                    </div>
                    {bottomVine && <div className={styles.bottomVine}>
                        <GatsbyImage
                            image={bottomVine.gatsbyImageData}
                            alt={`${bottomVine.title} image`}
                            objectFit='contain'
                            className={styles.bottomVineImg}
                        />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default SectionText